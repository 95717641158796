import * as React from 'react'
import Layout from '../components/index'
import '../styles/homepage.scss'

const HomePage = () => {
  return (
      <Layout nextBottomSlideBtn="Let's Go" nextBottomSlideBtnLink="/what-is-southern-360" id="home">
        <div className="wrapper">

          <div id="home-page-hero">
            <div className="left">
              <h1>Introducing Southern 360: <br/> Energy Solutions for a Climate Ready Future</h1>
            </div>
            <div className="right">
              <video muted autoPlay={true} loop>
                <source src="https://www.southerncompany.com/content/dam/southerncompany/images/360/04_southern360-animation_header_080921.mp4" type="video/mp4" />
              </video>
            </div>
          </div>
        </div>
      </Layout>
  )
}

export default HomePage